<template>
	<div class="h-full">
		<fixed-list :dataSource="dataSource" :config="config" @onPageChange="getList" :queryParam="queryParam">
			<div class="h-handle-button" slot="buttonGroup">
				<div class="h-b">
					<el-button type="primary" size="small" @click="btnRelation" v-right-code="'Customer_Menu_Stock_Predistribution_Relation'">货品关联</el-button>
				</div>
				<div class="h-b">
					<el-button type="primary" size="small" @click="btnDist" v-right-code="'Customer_Menu_Stock_Predistribution_Distributionion'">批量分配</el-button>
				</div>
				<div class="h-b">
					<el-button type="primary" size="small" @click="btnAverage" v-right-code="'Customer_Menu_Stock_Predistribution_Average'">未分配数量均分</el-button>
				</div>
				<div class="h-b">
					<el-button type="primary" size="small" @click="btnExport" v-right-code="'Customer_Menu_Stock_Predistribution_Export'">导出</el-button>
				</div>
			</div>
			<p slot="elList">
				<el-table ref="ruleOrderAssignListTable" :data="dataSource.Result" border highlight-current-row @selection-change="handleSelectionChange">
					<el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
					<el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams" :key="index" :prop="col.FieldName"
					 :label="col.DisplayName" :render-header="bindFilter(queryParam,col,ignoreField)" :fixed="index==0&&(!config||!config.isDetailDisplay)"
					 :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0" v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
						<template slot-scope="scope">
							<span>{{ scope.row[col.FieldName] }}</span>
						</template>

					</el-table-column>

				</el-table>
			</p>
		</fixed-list>
		<StockRelation  ref="stockRelation" :config="stockRelationConfig"></StockRelation>
		<StockRelationNumber :config="stockRelationNumberConfig"></StockRelationNumber>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				ignoreField: [],
				multipleSelection: [],
				queryParam: {
					pageIndex: 1,
					pageSize: 10,
					params: {}

				},
				dataSource: {
					ColDefs: {},
					Result: [],
					TotalCount: 0
				},
				stockRelationConfig: {
					isDetailDisplay: false,
					dialogFormVisible: false
				},
				stockRelationNumberConfig: {
					isDetailDisplay: false,
					DataList: [],
					dialogFormVisible: false
				}

			}
		},
		//父组件事件，控制显示和数据传值
		props: {
			config: {
				isDetailDisplay: false,
				isButtonlDisplay: false,
			},
			option: {}
		},
		methods: {
			//点击选中行
			handleSelectionChange: function(rows) {
				this.multipleSelection = rows;
			},
			//初始化获取列表数据
			initialize: function() {
				var _this = this;
				this.getList(this.queryParam);
			},
			//获取列表
			getList: function(param) {
				var _this = this;
				this.queryParam = param;
				this.$ajax.query("omsapi/stock/stockrelation/searchlist", "post", param, data => {
					if (data.Result != null) {
						data.Result.forEach(item => {
							item.IsShow = false;
						});
					}
					_this.dataSource = data;


				});
			},
			//点击货品关联
			btnRelation: function() {
				this.$refs.stockRelation.getData();
				this.stockRelationConfig.dialogFormVisible = true;
			},
			//点击分配
			btnDist: function() {
				var _this = this;
				if (_this.multipleSelection == null || _this.multipleSelection.length <= 0) {
					_this.Utils.messageBox(`请选择要操作的数据！`);
					return;
				};
				_this.stockRelationNumberConfig.DataList = JSON.parse(JSON.stringify(_this.multipleSelection));
				console.log("dddddddd",	_this.stockRelationNumberConfig.DataList);
				
				_this.stockRelationNumberConfig.dialogFormVisible = true;
			},
			//点击均分
			btnAverage: function() {
				var _this = this;
				if (_this.multipleSelection == null || _this.multipleSelection.length <= 0) {
					_this.Utils.messageBox(`请选择要操作的数据！`);
					return;
				};
				var iDs = _this.Utils.selectionsToArr(_this.multipleSelection, "StockQuotaID");
				var params = {
					StockQuotaIDs: iDs
				};
				this.$ajax.query("omsapi/stock/stockrelation/averageuserstock", "post", params, data => {
					_this.Utils.messageBox("操作成功！", "success");
					_this.initialize();
				});
			},
			//点击导出
			btnExport: function() {
				var _this = this;
				var str = encodeURIComponent(JSON.stringify(_this.queryParam));
				if (_this.dataSource.TotalCount >= 200000) {
					_this.Utils.confirm({
						title: "下载提示",
						content: "由于数据量太大，请等本次操作完成后，在进行其他操作哦！"
					}, () => {
						_this.Utils.download("omsapi/stock/stockrelation/exportstockrelation?currentType=" + _this.$store.state.global.currentType +
							"&currentCCode=" + _this.$store.state.global.currentCCode, _this.queryParam, "post", true);
					});
				} else {
					_this.Utils.download("omsapi/stock/stockrelation/exportstockrelation?currentType=" + _this.$store.state.global.currentType +
						"&currentCCode=" + _this.$store.state.global.currentCCode, _this.queryParam, "post", true);
				}

			},
			//关联商品确定事件
			onStockRelationOk: function() {
				this.stockRelationConfig.dialogFormVisible = false;
				this.initialize();
			},
			//分配用户库存确定事件
			onStockRelationNumberOk: function(index, row) {
				this.stockRelationNumberConfig.dialogFormVisible = false;
				this.initialize();
			}
		},
		mounted: function() {		
			this.initialize();
			//利用事件总线，使得兄弟组件方法可以相互触发
			this.Utils.lazy(() => {
				this.Event.$on("stockRelationList", () => this.onStockRelationOk());
				this.Event.$on("stockRelationNumberList", () => this.onStockRelationNumberOk());
			});
		},
		components: {
			"StockRelation": resolve => {
				require(['../../../selector/stockselector.vue'], resolve)
			},
			"StockRelationNumber": resolve => {
				require(['../../../selector/stocknumberselector.vue'], resolve)
			}
		},

	}
</script>
